import { IPaymentInfo, IPaymentType } from "@/types/global.types";
import { checkoutOrigin } from "@/utils/constants";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";

function StatusModal(
    {info, type}: {info: IPaymentInfo, type:IPaymentType}
) {

    function redirect() {
        if (info.redirect_url.length > 0 ) {
            window.location.href = info.redirect_url;
        }
    }

    function handleClosePopup() {
        if (info) {
            if (info.payment_status === "Pending") {
                window.parent.postMessage({ type: "closeMPWCheckoutModal", data: {} }, checkoutOrigin);
            }else if(info.payment_status === "Successful") {
                window.parent.postMessage({ type: "MPWCheckoutSuccess", data: {data:info, error: {}} }, checkoutOrigin);
            }else {
                window.parent.postMessage({ type: "MPWCheckoutFailed", data: {data: info, error: {}} }, checkoutOrigin);
            }
        }else {
            window.parent.postMessage({ type: "closeMPWCheckoutModal", data: {} }, checkoutOrigin);
        }
    }

    return (
        <>
            <div className='w-full flex h-screen justify-center bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-100 items-center'>
                <div className='-mt-[100px] lg:-mt-[130px]'>
                    <center className=''>
                        {
                            info.payment_status === "Successful" ?
                            <FaRegCheckCircle className='w-10 h-10 text-purple-700 mb-10' /> :
                            <FaRegTimesCircle className='w-10 h-10 text-purple-700 mb-10' />
                        }
                        <div className="mb-2">Transaction {info.payment_status}</div>
                        <div className="text-xs">Order reference code: <span className="text-purple-700">{info.order_reference_code}</span></div>
                        {
                            type === "hosted" ?
                            <button onClick={() => redirect()} className="text-xs">Go back to <span className="text-purple-700">{info.merchant.name}</span></button> :
                            <button onClick={() => handleClosePopup()} className="text-xs text-purple-700">Close</button>
                        }
                    </center>
                </div>
            </div>
        </>
    )
}

export default StatusModal