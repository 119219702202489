import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

function IframePopup(
    {
        url,
        setIsPaymentModalOpen,
        setIsLoading
    }:
        {
            url: string,
            setIsPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>
            setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
        }) {

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isLoadingIframe, setIsLoadingIframeLoading] = useState(true);

    const handleLoad = () => {
        setIsLoadingIframeLoading(false);
    };

    // In your React component
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // Check if the message is coming from the iframe
            if (event.source === iframeRef.current?.contentWindow) {
                // Handle the message here
                console.log("Message from iframe:", event.data);
                if (event.data.type === "MonnifyPopupCancel") {
                    setIsPaymentModalOpen(false);
                    setIsLoading(false);
                }
                if (event.data.type === "TRANSACTION_FAILED") {
                    setIsPaymentModalOpen(false);
                    setIsLoading(false);
                }
                if (event.data.type === "TRANSACTION_SUCCESS") {
                    setIsPaymentModalOpen(false);
                }
            }
        };

        // Add event listener for messages
        window.addEventListener("message", handleMessage);

        // Clean up the event listener
        return () => {
            window.removeEventListener("message", handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='fixed top-0 left-0 w-full bg-neutral-900 bg-opacity-85 h-screen flex justify-center md:pt-[120px]'>
                {isLoadingIframe && (
                    <div className='w-full h-[300px] flex items-center justify-center'>
                        <div>
                            <center className=''>
                                <AiOutlineLoading3Quarters className='w-8 h-8 text-purple-600 animate-spin mb-10' />
                                <div className='animate-ping text-[10px] text-white font-bold'>
                                    Please wait
                                </div>
                            </center>
                        </div>
                    </div>
                )}
                <iframe
                    src={url}
                    id="PartnerPaymentIframe"
                    title='Card embed payment partner'
                    style={{
                        border: 'none',
                        display: isLoadingIframe ? 'none' : 'block'
                    }}
                    className='iframe-popup w-full md:w-[500px]'
                    onLoad={handleLoad}
                    ref={iframeRef}
                />
            </div>
        </>
    )
}

export default IframePopup