import MobileMenu from "@/components/MobileMenu";
import { useApp } from "@/contexts/appContext";
import { calculateExpiryTime, numberFormat } from "@/utils/helpers";
import React, { useEffect, useState } from "react";
import { FaSlackHash } from "react-icons/fa";
import { GiPadlock } from "react-icons/gi";
import { useToast } from "@chakra-ui/react";
import { handleResponseError } from "@/utils/errors";
import http from "@/lib/http";
import { IDynamicVirtualAccountUSSD } from "@/types/global.types";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ImSpinner8 } from "react-icons/im";
import { TbExclamationCircle } from "react-icons/tb";
import CountdownTimer from "@/components/CountdownTimer";

function Ussd() {
  const { info, setInfo } = useApp();
  const toast = useToast();

  const [tab, setTab] = useState<"paid" | "info">("info");
  const [loadSupportedBanks, setLoadSupportedBanks] = useState(false);
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [institution, setInstitution] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const [isFetchingDynamicVirtualAccount, setIsFetchingDynamicVirtualAccount] =
    useState(false);
  const [dynamicVirtualAccount, setDynamicVirtualAccount] =
    useState<IDynamicVirtualAccountUSSD | null>(null);

  const fetchSupportedBanks = async () => {
    try {
      setLoadSupportedBanks(true);
      const res = await http(
        "wallet/collection/method/ussd/supported-institutions",
        {
          meta: {
            currency_code: info?.currency,
          },
        }
      );

      setSupportedBanks(res?.data ?? []);

      setLoadSupportedBanks(false);
    } catch (error) {
      setLoadSupportedBanks(false);
      handleResponseError(error, toast);
    }
  };

  useEffect(() => {
    fetchSupportedBanks();
    // eslint-disable-next-line
  }, []);

  const fetchDynamicVirtualAccount = async () => {
    try {
      setIsFetchingDynamicVirtualAccount(true);
      const resp = await http("wallet/collection/method/ussd/create", {
        order_reference_code: info?.order_reference_code,
        meta: {
          institution,
        },
      });
      setDynamicVirtualAccount(resp.data ? resp.data : null);
      setIsFetchingDynamicVirtualAccount(false);
      setCurrentStep(2);
    } catch (error) {
      console.log(error);
      handleResponseError(error, toast);
      setIsFetchingDynamicVirtualAccount(false);
    }
  };

  const generateUSSD = () => {
    if (loadSupportedBanks === false && institution.length > 0) {
      fetchDynamicVirtualAccount();
    } else {
      toast({
        title: "Validation Failed",
        description: "Kindly select bank.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const checkStatus = (stopAfter: number = 20) => {
    if (stopAfter <= 0) {
      console.log("done");
      return;
    }
    setTimeout(async () => {
      try {
        const resp = await http("wallet/collection/info", {
          order_reference_code: info?.order_reference_code,
        });
        setInfo((e) =>
          e !== null ? { ...e, payment_status: resp.data.payment_status } : null
        );
        if (resp.data.payment_status === "Pending") {
          checkStatus(stopAfter - 1);
        }
      } catch (error) {
        console.log(error);
        handleResponseError(error, toast);
        checkStatus(stopAfter - 1);
      }
    }, 5000);
  };

  const confirmPayment = () => {
    setTab("paid");
    checkStatus();
  };

  return (
    <>
      <div className="mb-5 md:mb-10">
        <div className="flex items-center justify-between p-4 border-b-2 bg-purple-50 md:bg-inherit md:border-b-0 md:p-0">
          <div className="p-4 rounded-full md:bg-purple-900 md:bg-opacity-45">
            <img src={"/moipayway-icon.png"} className="w-6" alt="icon" />
          </div>
          <div className="flex flex-col items-end text-[11px] md:text-sm pr-2">
            <div className="font-normal">{info?.merchant.name}</div>
            <div className="font-bold md:font-normal">{info?.user.email}</div>
            <div className="font-bold">
              {info?.currency?.toUpperCase()}{" "}
              {numberFormat(info ? info.amount?.toString() : "")}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2 px-5 mb-6 md:hidden">
        <div className="flex items-center justify-center p-2 bg-purple-900 rounded-full bg-opacity-90">
          <FaSlackHash className="w-3 h-3 text-neutral-100" />
        </div>
        <div className="text-sm font-bold text-neutral-900">USSD</div>
      </div>

      {currentStep === 1 ? (
        <>
          <div className="flex justify-center w-full mb-4 text-xs md:text-sm md:mb-8">
            <div className="p-1 border-b-2 border-b-purple-900">
              <center>Select your bank to begin payment</center>
            </div>
          </div>

          <div className="w-full flex justify-center mb-[100px]">
            <div className="w-full md:w-[60%]">
              <center>
                <div className="w-[80%] md:w-full bg-purple-50 p-2 mb-5 border-2 border-purple-100">
                  <div className="pl-2 text-xs font-bold text-left text-neutral-800 text-opacity-85">
                    SELECT BANK
                  </div>
                  <select
                    className="outline-none w-full p-2 bg-transparent text-neutral-900 text-opacity-50 text-sm md:text-[15px]"
                    onChange={(e) => {
                      setInstitution(e.target.value);
                    }}
                  >
                    {loadSupportedBanks ? (
                      <option value="" disabled>
                        Loading banks...
                      </option>
                    ) : (
                      <option value="">Click to select</option>
                    )}
                    {supportedBanks.map(
                      (item: { institution: string }, index: number) => (
                        <option
                          value={item?.institution}
                          key={`${index + 1}-bank-${item?.institution}`}
                        >
                          {item?.institution}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </center>

              <div className="mb-10">
                <center>
                  <button
                    type="button"
                    className="w=[60%] md:w-full bg-purple-900 text-neutral-50 rounded-md p-3 text-sm font-bold"
                    onClick={() => {
                      generateUSSD();
                    }}
                  >
                    {isFetchingDynamicVirtualAccount
                      ? "Loading..."
                      : "Generate USSD"}
                    {/* Pay {info?.currency?.toUpperCase()}{" "}
                {numberFormat(info!.amount?.toString())} */}
                  </button>
                </center>
              </div>

              <div className="flex justify-center">
                <div className="flex justify-between gap-3">
                  <GiPadlock className="w-4 h-4" />
                  <div className="text-xs">
                    Secured by <b>MoiPayWay</b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block md:hidden">
            <MobileMenu />
          </div>
        </>
      ) : (
        <>
          {!isFetchingDynamicVirtualAccount && dynamicVirtualAccount ? (
            <>
              {tab === "info" ? (
                <>
                  <div className="flex justify-center w-full mb-4 text-xs md:text-sm md:mb-8">
                    <div className="p-1 border-b-2 border-b-purple-900">
                      <center>
                        Proceed to complete this
                        transfer
                      </center>
                    </div>
                  </div>

                  <div className="flex justify-center w-full mb-2 text-sm">
                    <CountdownTimer
                      duration={dynamicVirtualAccount.meta.duration}
                      durationUnit={
                        dynamicVirtualAccount.meta.duration_unit as any
                      }
                    />
                  </div>

                  <div className="flex justify-center w-full">
                    <div className="w-full md:w-[60%]">
                      <div className="w-full px-6 py-8 mb-5 bg-purple-50">
                        <div className="mb-6">
                          <div className="text-[10px] text-neutral-800 text-opacity-85">
                            AMOUNT
                          </div>
                          <div className="font-bold text-neutral-800 text-opacity-90">
                            {info?.currency?.toUpperCase()} {info?.amount}
                          </div>
                        </div>
                        <div className="mb-6">
                          <div className="text-[10px] text-neutral-800 text-opacity-85">
                            USSD CODE
                          </div>
                          <div className="font-bold text-neutral-800 text-opacity-90">
                            {dynamicVirtualAccount?.meta?.ussd}
                          </div>
                        </div>
                        <div className="mb-6">
                          <div className="text-[10px] text-neutral-800 text-opacity-85">
                            BANK NAME
                          </div>
                          <div className="font-bold text-neutral-800 text-opacity-90">
                            {dynamicVirtualAccount?.meta?.bank_name}
                          </div>
                        </div>
                        {/* <div className="mb-8">
                          <div className="text-[10px] text-neutral-800 text-opacity-85">
                            BENEFICIARY
                          </div>
                          <div className="font-bold text-neutral-800 text-opacity-90">
                            {dynamicVirtualAccount.meta.account_name}
                          </div>
                        </div> */}
                        <hr />
                        <div className="flex justify-between w-full gap-3 px-2">
                          <TbExclamationCircle className="w-4 h-4" />
                          <div className="flex-1 text-[10px]">
                            This USSD was generated for this transaction only.
                            It is only valid for this specific transaction and
                            will expire by{" "}
                            {calculateExpiryTime(
                              dynamicVirtualAccount.meta.duration,
                              dynamicVirtualAccount.meta.duration_unit as any
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4 md:mb-10">
                        <center>
                          <button
                            onClick={() => confirmPayment()}
                            type="button"
                            className="w-[60%] md:w-full bg-purple-900 text-neutral-50 rounded-md p-3 text-xs font-bold"
                          >
                            I have made this bank transfer
                          </button>
                        </center>
                      </div>

                      <div className="flex justify-center">
                        <div className="flex justify-between gap-3">
                          <GiPadlock className="w-4 h-4" />
                          <div className="text-xs">
                            Secured by <b>MoiPayWay</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="block md:hidden">
                    <MobileMenu />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify-center w-full">
                    <div className="w-[300px] pt-[50px]">
                      <center>
                        <div className="inline-block p-5 mb-5 text-purple-900 rounded-full bg-neutral-100">
                          <ImSpinner8 className="w-8 h-8 animate-spin" />
                        </div>
                        <div className="mb-4 text-md">
                          Payment is being confirmed. please do not refresh this
                          page
                        </div>
                      </center>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="w-full flex justify-center h-[500px] items-center">
                <AiOutlineLoading3Quarters className="w-8 h-8 mb-10 text-purple-700 animate-spin" />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Ussd;
