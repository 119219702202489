import React, { useEffect } from "react";

import { numberFormat } from "@/utils/helpers";
import MobileMenu from "@/components/MobileMenu";
import { useApp } from "@/contexts/appContext";
import { GiPayMoney } from "react-icons/gi";
import { FaCreditCard } from "react-icons/fa6";
import { FaSlackHash } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { paymentMethodLinks } from "@/utils/constants";

function Home() {
  const { info } = useApp();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function getPath(path?: number) {
    const split_path = pathname.split("/");
    return split_path[path ? path : split_path.length - 1];
  }

  useEffect(() => {
    if (info && JSON.parse(info.payment_method.allowed).length === 1) {
      navigate(
        `/${getPath(1)}/${info.order_reference_code}/${
          (paymentMethodLinks as any)[
            JSON.parse(info.payment_method.allowed)[0]
          ]
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return (
    <>
      <div>
        <div className="mb-0 md:mb-10">
          <div className="flex items-center justify-between p-4 border-b-2 bg-purple-50 md:bg-inherit md:border-b-0 md:p-0">
            <div className="p-4 rounded-full md:bg-purple-900 md:bg-opacity-45">
              <img src={"/moipayway-icon.png"} className="w-6" alt="icon" />
            </div>
            <div className="flex flex-col items-end text-[11px] md:text-sm pr-2">
              <div className="font-normal">{info?.merchant.name}</div>
              <div className="font-bold md:font-normal">{info?.user.email}</div>
              <div className="font-bold">
                {info?.currency?.toUpperCase()}{" "}
                {numberFormat(info ? info.amount.toString() : "")}
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex justify-center w-full pb-[100px] bg-no-repeat overflow-hidden bg-green-500 md:bg-inherit bg-right-top md:bg-left-top"
          style={{
            backgroundImage: "url(/money-illus.svg)",
            backgroundSize: "450px",
          }}
        >
          <div className="w-full md:w-[400px] pt-[50px] px-8 md:px-0">
            <div className="inline-block p-4 mb-5 text-purple-900 rounded-full bg-neutral-100 text-opacity-80">
              <GiPayMoney className="w-10 h-10" />
            </div>
            <div className="mb-8 text-2xl font-bold text-left">
              How would <br /> You like to pay ?
            </div>
            <div className="hidden w-full gap-1 md:grid md:grid-cols-3">
              {info &&
                JSON.parse(info.payment_method.allowed).includes(
                  "card_embed"
                ) && (
                  <div
                    className="p-2 text-center rounded-lg shadow-md cursor-pointer bg-neutral-50 bg-opacity-90"
                    onClick={() =>
                      navigate(
                        `/hosted/${info?.order_reference_code}/card-embed`
                      )
                    }
                  >
                    <div className="mb-1 underline-offset-2">Card</div>
                    <div className="inline-block p-4 bg-purple-900 rounded-full bg-opacity-90">
                      <FaCreditCard className="w-4 h-4 text-white" />
                    </div>
                  </div>
                )}

              {info &&
                JSON.parse(info.payment_method.allowed).includes(
                  "dynamic_virtual_account"
                ) && (
                  <div
                    className="p-2 text-center rounded-lg shadow-md cursor-pointer bg-neutral-50 bg-opacity-90"
                    onClick={() =>
                      navigate(
                        `/hosted/${info?.order_reference_code}/bank-transfer`
                      )
                    }
                  >
                    <div className="mb-1 underline-offset-2">Bank Transfer</div>
                    <div className="inline-block p-4 bg-purple-900 rounded-full bg-opacity-90">
                      <GrTransaction className="w-4 h-4 text-white" />
                    </div>
                  </div>
                )}

              {info &&
                JSON.parse(info.payment_method.allowed).includes("ussd") && (
                  <div
                    className="p-2 text-center rounded-lg shadow-md cursor-pointer bg-neutral-50 bg-opacity-90"
                    onClick={() =>
                      navigate(`/hosted/${info?.order_reference_code}/ussd`)
                    }
                  >
                    <div className="mb-1 underline-offset-2">USSD</div>
                    <div className="inline-block p-4 bg-purple-900 rounded-full bg-opacity-90">
                      <FaSlackHash className="w-4 h-4 text-white" />
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="block md:hidden">
          <MobileMenu />
        </div>
      </div>
    </>
  );
}

export default Home;
