"use client"

import { FaCreditCard } from "react-icons/fa6";
import { GrTransaction } from "react-icons/gr";
import { FaSlackHash } from "react-icons/fa";
import classNames from "classnames";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useApp } from "@/contexts/appContext";
import { useEffect } from "react";
import Loader from "./Loader";
import StatusModal from "./StatusModal";
import { IPaymentType } from "@/types/global.types";
import http from "@/lib/http";
import { FaRegTimesCircle } from "react-icons/fa";
import { checkoutOrigin } from "@/utils/constants";

export default function PopupLayout() {

    const params = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { info, isFetching, setIsFetching, setInfo } = useApp();

    useEffect(() => {
        if (!isFetching && (!info || !info.order_reference_code)) {
            navigate('/404', { replace: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, info]);

    async function getInfo() {
        setIsFetching(true);
        try {
            const resp = await http("wallet/collection/info", { order_reference_code: params.reference_code });
            setInfo(resp.data);
            console.log(resp.data)
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        getInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClosePopup() {
        if (info) {
            if (info.payment_status === "Pending") {
                window.parent.postMessage({ type: "closeMPWCheckoutModal", data: {} }, checkoutOrigin);
            } else if (info.payment_status === "Successful") {
                window.parent.postMessage({ type: "MPWCheckoutSuccess", data: { data: info, error: {} } }, checkoutOrigin);
            } else {
                window.parent.postMessage({ type: "MPWCheckoutFailed", data: { data: info, error: {} } }, checkoutOrigin);
            }
        } else {
            window.parent.postMessage({ type: "closeMPWCheckoutModal", data: {} }, checkoutOrigin);
        }
    }

    function getPath(path?: number) {
        const split_path = pathname.split('/');
        return split_path[path ? path : split_path.length - 1];
    }

    return (
        <>
            <div className="fixed w-full px-3 md:px-10 h-[50px] bg-neutral-700 bg-opacity-50">
                <div className="flex justify-between items-center h-[50px] ">
                    <div></div>
                    <div>
                        <button onClick={() => handleClosePopup()} className="flex gap-2 text-sm items-center text-neutral-50 font-bold px-2 py-1 bg-neutral-600 rounded-full">
                            <FaRegTimesCircle />
                            <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="px-3 py-0 md:px-5 md:py-1 bg-neutral-700 bg-opacity-50 main-div relative top-[50px]">
                <div className="w-full font-container">
                    {!isFetching && info && info.order_reference_code ?
                        info.payment_status === "Pending" ?
                            <div className="bg-white">
                                <div className='grid md:flex gap-5 md:h-lvh bg-purple-50 bg-opacity-40  w-full'>
                                    <div className='md:min-w-[300px] md:w-[30%] w-full bg-neutral-50 hidden md:block'>
                                        {
                                            /* <div className='flex justify-end p-10'>
                                                <Image src={'https://moipayway.com/wp-content/uploads/2023/04/moipayway.png'} className='w-[120px]' alt='logo' width={100} height={30} />
                                            </div> */
                                        }
                                        <div className=' hidden sm:flex sm: h-full sm:pt-36 flex-col items-end w-full'>
                                            <div className='px-10 py-3 text-xs mb-2'>
                                                <div className='font-bold border-b-purple-800 border-b-4'>PAYMENT METHODS</div>
                                            </div>
                                            {
                                                info && JSON.parse(info.payment_method.allowed).includes("card_embed") &&
                                                <Link to={`/popup/${info?.order_reference_code}/card-embed`} className={classNames('px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                                                    'bg-purple-200 text-purple-900': getPath() === "card-embed",
                                                    'bg-neutral-50 text-neutral-700 text': getPath() !== "card-embed",
                                                })}>
                                                    <div className='flex items-center gap-2'>
                                                        <div>Card</div>
                                                        <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                                            'bg-purple-900': getPath() === "card-embed",
                                                            'bg-neutral-300': getPath() !== "card-embed",
                                                        })}>
                                                            <FaCreditCard className={classNames({
                                                                'text-neutral-100': getPath() === "card-embed",
                                                                'text-neutral-900': getPath() !== "card-embed",
                                                            })} />
                                                        </div>
                                                    </div>
                                                </Link>}

                                            {
                                                info && JSON.parse(info.payment_method.allowed).includes("dynamic_virtual_account") &&
                                                <Link to={`/popup/${info?.order_reference_code}/bank-transfer`} className={classNames('px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                                                    'bg-purple-200 text-purple-900': getPath() === "bank-transfer",
                                                    'bg-neutral-50 text-neutral-700 text': getPath() !== "bank-transfer",
                                                })}>
                                                    <div className='flex items-center gap-2'>
                                                        <div>Bank Transfer</div>
                                                        <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                                            'bg-purple-900': getPath() === "bank-transfer",
                                                            'bg-neutral-300': getPath() !== "bank-transfer",
                                                        })}>
                                                            <GrTransaction className={classNames({
                                                                'text-neutral-100': getPath() === "bank-transfer",
                                                                'text-neutral-900': getPath() !== "bank-transfer",
                                                            })} />
                                                        </div>
                                                    </div>
                                                </Link>}

                                            {
                                                info && JSON.parse(info.payment_method.allowed).includes("ussd") &&
                                                <Link to={`/popup/${info?.order_reference_code}/ussd`} className={classNames('px-10 py-4 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                                                    'bg-purple-200 text-purple-900': getPath() === "ussd",
                                                    'bg-neutral-50 text-neutral-700 text': getPath() !== "ussd",
                                                })}>
                                                    <div className='flex items-center gap-2'>
                                                        <div>USSD</div>
                                                        <div className={classNames('w-8 h-8 rounded-full flex justify-center items-center', {
                                                            'bg-purple-900': getPath() === "ussd",
                                                            'bg-neutral-300': getPath() !== "ussd",
                                                        })}>
                                                            <FaSlackHash className={classNames({
                                                                'text-neutral-100': getPath() === "ussd",
                                                                'text-neutral-900': getPath() !== "ussd",
                                                            })} />
                                                        </div>
                                                    </div>
                                                </Link>}
                                        </div>
                                    </div>
                                    <div className='bg-opacity-40 pt-0 pb-0 md:pt-36 md:pb-20 flex justify-center md:flex-1 '>
                                        <div className='w-full md:w-[600px] px-0'>
                                            <Outlet />
                                            <div className="py-0 md:py-10"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <StatusModal info={info} type={getPath(1) as IPaymentType} />
                        :
                        <Loader />
                    }
                </div>
            </div>
        </>
    );
}
